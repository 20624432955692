<template>
<div class="con">

  <div class="jdsshmCls">
    <div class="jdssJ1Cls"></div>
    <div class="jdssJ2Cls"></div>
    <div style="height:10px"></div>
    <div class="boxTitleCls">基地大数据<img src="../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
    <div class="shujuTableCls">
      <div class="jdsjCls">
        <div>种植总面积</div>
        <div>{{info.jidiBigdataTotalPlantingArea || 0}}<span>亩</span></div>
      </div>
      <div class="splitCls"></div>
      <div class="jdsjCls">
        <div>种植地块数</div>
        <div>{{info.jidiBigdataNumberOfPlantingPlots || 0}}<span>块</span></div>
      </div>
      <div class="splitCls"></div>
      <div class="jdsjCls">
        <div>种植品种数</div>
        <div>{{info.jidiBigdataNumberOfPlantingVarieties || 0}}<span>类</span></div>
      </div>
    </div>
    <div class="shujuTableCls">
      <div class="jdsjCls">
        <div>技术人员数</div>
        <div>{{info.jidiBigdataNumberOfTechnicians || 0}}<span>人</span></div>
      </div>
      <div class="splitCls"></div>
      <div class="jdsjCls">
        <div>管理人员数</div>
        <div>{{info.jidiBigdataNumberOfManagementPersonnel || 0}}<span>人</span></div>
      </div>
      <div class="splitCls"></div>
      <div class="jdsjCls">
        <div>农机具台</div>
        <div>{{info.jidiBigdataNumberOfAgriculturalMachinery || 0}}<span>套</span></div>
      </div>
    </div>
    <div class="btfusCls">
      <div class="zzmjBoxCls">
        <div class="boxTitleCls">种植面积<img src="../../assets/images/dataScreen/boxTitleBgs.png"></img>
        </div>
        <div class="jisuifBgCls">
          <div>{{info.plantingAreaAttr1}}</div>
          <div>{{info.plantingAreaValue1}}<span>{{info.plantingAreaUnit1}}</span></div>
        </div>
        <div class="jisuifBgCls">
          <div>{{info.plantingAreaAttr2}}</div>
          <div>{{info.plantingAreaValue2}}<span>{{info.plantingAreaUnit2}}</span></div>
        </div>
        <div class="jisuifBgCls">
          <div>{{info.plantingAreaAttr3}}</div>
          <div>{{info.plantingAreaValue3}}<span>{{info.plantingAreaUnit3}}</span></div>
        </div>
      </div>
      <div class="yugunianzongliangBoxCls">
        <div class="boxTitleCls">预估年总产量 | 平均亩产<img
            src="../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
        <div class="jisuif2BgCls" v-for="(item, i) in datascreen11AnnualTotalYieldBOList" :key="i">
          <div>{{item.parcelName}}</div>
          <div>{{item.totalOutput}}公斤</div>
          <div>|</div>
          <div>{{item.averageYield}}公斤/亩</div>
        </div>

      </div>
    </div>
    <div class="boxTitleCls">基地实时画面<img src="../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
    <div class="shujuTableCls" v-if="isTrue">
      <div class="vedioCls">
        <EZUIKitJs2 :msg="'video1'" :title="video1Title" :attribute="webcamAttribute"
                    :flv="info.jidiVedio1Url" />

        <div class="vedioTitleCls">{{info.jidiVedio1Title}}</div>
      </div>
      <div class="vedioCls">
        <EZUIKitJs2 :msg="'video2'" :title="video2Title" :attribute="webcamAttribute"
                    :flv="info.jidiVedio2Url" />

        <div class="vedioTitleCls">{{info.jidiVedio2Title}}</div>
      </div>
    </div>
  </div>
  <div class="jdsshmCls" style="margin-left: 0.5%;width:30%;padding: 22px 10px 10px 10px;">
    <div class="jdssJ1Cls"></div>
    <div class="jdssJ2Cls"></div>
    <img :src="info.vedio3dUrl" class="guwuzaliangzhongdituCls"/>

  </div>

  <div class="jdsshmCls" style="margin-left: 0.5%;width:36%;">
    <div class="jdssJ1Cls"></div>
    <div class="jdssJ2Cls"></div>
    <div style="height:10px"></div>
    <div class="boxTitleCls">基地气象站<img src="../../assets/images/dataScreen/boxTitleBgs.png"></img></div>

    <div class="qixiangzhanCls">
      <div class="wendubiaoCls">6.4</div>
      <div class="wuliansbCls">
        <div class="wlsbRowCls">
          <div class="wlsb1IconCls">东北风</div>
          <div>
            <span class="status1Cls"></span>
            <span>0</span>
            <span>m/s</span>
          </div>
        </div>
        <div class="wlsbRowCls">
          <div class="wlsb2IconCls">湿度</div>
          <div>
            <span class="status1Cls"></span>
            <span>71.4</span>
            <span>Rh</span>
          </div>
        </div>
        <div class="wlsbRowCls">
          <div class="wlsb3IconCls">雨量</div>
          <div>
            <span class="status2Cls"></span>
            <span>0</span>
            <span>mm</span>
          </div>
        </div>
        <div class="wlsbRowCls">
          <div class="wlsb4IconCls">CO2</div>
          <div>
            <span class="status1Cls"></span>
            <span>1402</span>
            <span>ppm</span>
          </div>
        </div>
        <div class="wlsbRowCls">
          <div class="wlsb5IconCls">光照</div>
          <div>
            <span class="status1Cls"></span>
            <span>0</span>
            <span>Lux</span>
          </div>
        </div>
        <div class="wlsbRowCls">
          <div class="wlsb6IconCls">大气压</div>
          <div>
            <span class="status1Cls"></span>
            <span>88.4</span>
            <span>hPa</span>
          </div>
        </div>
      </div>
    </div>

    <div class="boxTitleCls" style="margin-top:50px">基地土壤指标监测<img
        src="../../assets/images/dataScreen/boxTitleBgs.png"></img></div>
    <div class=" zbsjiyeuCls">
      <div class="qixiangzbsjCls1">地块</div>
      <div class="qixiangzbsjCls2">土壤PH值</div>
      <div class="qixiangzbsjCls3">土壤温度</div>
      <div class="qixiangzbsjCls4">土壤水分</div>
      <div class="qixiangzbsjCls5">土壤盐分</div>
      <div class="qixiangzbsjCls6">土壤电导率</div>
    </div>
    <div class="zbsjiyeuDataCls">
      <div class="zbsjiyeuDataValCls1">1号地</div>
      <div class="zbsjiyeuDataValCls2">7.2</div>
      <div class="zbsjiyeuDataValCls3">9.7℃</div>
      <div class="zbsjiyeuDataValCls4">20.2%</div>
      <div class="zbsjiyeuDataValCls5">0</div>
      <div class="zbsjiyeuDataValCls6">0.8</div>
    </div>
    <div class="zbsjiyeuDataCls">
      <div class="zbsjiyeuDataValCls1">2号地</div>
      <div class="zbsjiyeuDataValCls2">7.1</div>
      <div class="zbsjiyeuDataValCls3">6.7℃</div>
      <div class="zbsjiyeuDataValCls4">20.1%</div>
      <div class="zbsjiyeuDataValCls5">0</div>
      <div class="zbsjiyeuDataValCls6">0.4</div>
    </div>
    <div class="zbsjiyeuDataCls">
      <div class="zbsjiyeuDataValCls1">3号地</div>
      <div class="zbsjiyeuDataValCls2">7.4</div>
      <div class="zbsjiyeuDataValCls3">6.8℃</div>
      <div class="zbsjiyeuDataValCls4">19.1%</div>
      <div class="zbsjiyeuDataValCls5">0</div>
      <div class="zbsjiyeuDataValCls6">0.6</div>
    </div>

    <div class="zuihouCls">
      <div class="zuihLeftCls">
        <div class="boxTitleCls">水肥情况<img src="../../assets/images/dataScreen/boxTitleBgs.png"></img>
        </div>
        <div class="ysltjBoxCls">
          <div>用水量统计</div>
          <div>亩均 {{info.waterStatisticsAvgMu}}吨</div>
          <div>|</div>
          <div>总量</div>
          <div>{{info.waterStatisticsTotalVal}}吨</div>
        </div>
        <div class="yfltjBoxCls">
          <div>用肥量统计</div>
          <div>亩均 {{info.fertilizerStatisticsAvgMu}}吨</div>
          <div>|</div>
          <div>总量</div>
          <div>{{info.fertilizerStatisticsTotalVal}}吨</div>
        </div>
      </div>
      <div class="zuihRightCls">
        <div class="boxTitleCls">农事预警<img src="../../assets/images/dataScreen/boxTitleBgs.png"></img>
        </div>
        <div class="sysdgBoxCls" v-for="(item, i) in datascreen11WarnBOList" :key="i">
          <div>{{item.warnName}}</div>
          <div>{{item.exceedanceName}}</div>
        </div>
        <!-- <div class="sysdg2BoxCls">
            <div>东区滴灌</div>
            <div>土壤水分招标</div>
        </div> -->
      </div>
    </div>
  </div>

</div>
</template>

<script>
import EZUIKitJs2 from '../../components/EZUIKitJs2.vue'
import 'animate.css';
export default {
  name: '',
  components: {
    EZUIKitJs2
  },
  data() {
    return {
      info: null,
      //预估年总产量 | 平均亩产
      datascreen11AnnualTotalYieldBOList: [],
      //一升小米统计
      datascreen11OrderBOList: [],
      isTrue: false,
      //种植日志
      //农事预警
      datascreen11WarnBOList: [],
      datascreen11PlantingLogBOList: [],
      webcamAttribute: {
        width: 271,
        height: 226
      },
      video1Title: '1',
      video2Title: '2',
      weiyangRecordList: [],
      day7List: [],
      currDay: 0,
      timeNum: 0,
      show: false,
      vedioTitle: "",
      player: null
    }
  },
  props: {
    _timeFmt: ""
  },
  computed: {
    weiyangTimeCompute() {
      return (_timeFmt) => {
        var rds = _timeFmt.split(":");
        var fenCounts = parseInt(rds[0]) * 60 + parseInt(rds[1]);

        //left: 13501px;
        // console.log(fenCounts/1440*1440);
        return fenCounts - 104;
      }
    }
  },
  mounted() {
    this.doPlay("");
    var timer = setInterval(() => {
      ++this.timeNum
      console.log(this.timeNum)
      if (this.timeNum > 4) {
        clearInterval(timer)
      }
    }, 50);

    //获取喂养时间数据
    this.getWeiyangRecords();
    //获取前7日
    this.getday7List();

    this.getInfo()

  },
  methods: {
    getInfo() {
      this.qa.queryDatascreen11ById({}).then(res => {
        if(res.respCode == '0000') {
          this.info = res.data
          this.isTrue = true;
          if(res.data.datascreen11AnnualTotalYieldBOList != null) {
            this.datascreen11AnnualTotalYieldBOList = res.data.datascreen11AnnualTotalYieldBOList
          }
          if(res.data.datascreen11OrderBOList != null) {
            this.datascreen11OrderBOList = res.data.datascreen11OrderBOList;
          }
          if(res.data.datascreen11PlantingLogBOList != null) {
            this.datascreen11PlantingLogBOList = res.data.datascreen11PlantingLogBOList;
          }

          if(res.data.datascreen11WarnBOList != null) {
            this.datascreen11WarnBOList = res.data.datascreen11WarnBOList;
          }
        }
        console.log(res.respCode)
      })
    },
    changeWeiyangDay(_day) {
      this.currDay = _day;
    },
    getday7List() {
      this.currDay = new Date().getDate();
      this.day7List = [];
      for (let i = 6; i >= 0; i--) {
        this.day7List.push(this.currDay - i);
      }
    },
    getWeiyangRecords() {
      this.weiyangRecordList = ["5:00", "12:00", "24:00"];
    },
    goDataScreen2() {
      this.$emit('tabsTypeClick', '2');
    },
    doPlay(_url) {
      this.qa.getAccessToken({}).then(res => {
        this.player = new EZUIKit.EZUIKitPlayer({
          autoplay: true,
          id: "video111",
          accessToken: res.data.accessToken,
          url: _url,
          template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
          // 视频上方头部控件
          //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
          //plugin: ['talk'],                       // 加载插件，talk-对讲
          // 视频下方底部控件
          // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
          audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
          // openSoundCallBack: data => console.log("开启声音回调", data),
          // closeSoundCallBack: data => console.log("关闭声音回调", data),
          // startSaveCallBack: data => console.log("开始录像回调", data),
          // stopSaveCallBack: data => console.log("录像回调", data),
          // capturePictureCallBack: data => console.log("截图成功回调", data),
          // fullScreenCallBack: data => console.log("全屏回调", data),
          // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
          width: 3080,
          height: 1560
        });
      });
    },
    transitionFun(_url, _title) {


      this.doPlay(_url);

      this.show = !this.show;
      this.vedioTitle = _title;
    }
  }
}
</script>

<style scoped>

.con{
  width: 1920px;
  height: 1080px;
  overflow: scroll;
  background:#0a131f;
}
div.jdsshmCls {
  width: 33%;
  height: 100%;
  float: left;
  background: #0E1639;
  border: 2px solid #123171;
  position: relative;
  padding: 30px 20px;
}

div.jdssJ1Cls {
  position: absolute;
  background-image: url("../../assets/images/dataScreen/ysSt-jiao.png");
  width: 59px;
  height: 59px;
  top: -7px;
  left: -7px;
}

div.jdssJ2Cls {
  position: absolute;
  background-image: url("../../assets/images/dataScreen/zxSt-jiao.png");
  width: 59px;
  height: 59px;
  bottom: -7px;
  right: -7px;

}

div.jdsshmCls>div.jdssTitleCls {
  background-image: url("../../assets/images/dataScreen/malinshu-title.png");
  width: 1164px;
  height: 148px;
  line-height: 148px;
  font-size: 55px;
  padding-left: 55px;
}

div.main3cls>div.jdtj2Cls {
  width: 1501px;
  height: 100%;
  float: left;
  margin-left: 53px;
}

div.jdtj2Cls>div.jdtj2_1Cls {
  width: 100%;
  height: 1379px;
  position: relative;
  background: #091A38;
  border: 2px solid #1585D5;

}

div.jdtj2Cls>div.jdtj2_1Cls.secCls {
  margin-top: 50px;
  height: 1352px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jdtjTitleBgCls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/tjTitleBg.png");
  width: 1497px;
  height: 139px;
  text-align: center;
  font-size: 48px;
  line-height: 139px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao1Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao1.png");
  width: 48px;
  height: 48px;
  position: absolute;
  top: -6px;
  left: -6px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao2Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao2.png");
  width: 48px;
  height: 48px;
  position: absolute;
  top: -6px;
  right: -6px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao3Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao3.png");
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: -6px;
  right: -6px;
}

div.jdtj2Cls>div.jdtj2_1Cls>div.jiao4Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao4.png");
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: -6px;
  left: -6px;
}

div.qnklxhltjCls {
  width: 100%;
  font-size: 45px;
  text-align: center;
  height: 100px;
  line-height: 100px;
}

div.qnklxhltjBoxCls {
  width: 100%;
  height: 500px;
  padding-left: 40px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls {

  background-size: 242px 195px;
  background-image: url("../../assets/images/dataScreen/zhibiaoBg.png");
  width: 242px;
  height: 195px;
  float: left;
  margin: 20px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(1) {
  font-size: 60px;
  font-family: DFPLiJinHeiW8-GB;
  font-weight: 400;
  text-align: center;
  padding-top: 20px;
  color: #00D3DB;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(1)>span {
  font-size: 40px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(2) {
  font-size: 36px;
  padding-top: 20px;
  text-align: center;
}

div.ywsjtjCls {
  height: 100px;
  font-size: 40px;
  text-align: center;
}

div.ywsjtjBoxCls {
  height: 300px;
  position: relative;
}

div.ywsjtjBoxCls>div.timerBarCls {
  position: absolute;
  width: 1440px;
  height: 107px;
  background: #0f2f5c;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
}

div.ywsjtjBoxCls>div.timerBarCls>span {
  flex: 1;
  font-size: 30px;
  display: block;
  line-height: 107px;
  text-align: right;
}

div.ywsjtjBoxCls>div.weishiRecordCls {
  margin-top: 30px;
  background-size: 45px 91px;
  background-position: center top;
  background-image: url("../../assets/images/dataScreen/weishiRecBg.png");
  background-repeat: no-repeat;
  position: absolute;
  color: #dfcc50;
  font-size: 30px;
  height: 200px;
  padding-top: 100px;
  position: absolute;

}

div.dayBarCls {
  height: 97px;
  line-height: 97px;
  width: 1440px;
  margin-left: 30px;
  display: flex;
}

div.dayBarCls>div {
  float: left;
  font-size: 30px;
  flex: 1;
  text-align: center;
  border: 3px solid #07375A;
  cursor: pointer;
}

div.dayBarCls>div:nth-child(n+2) {
  margin-left: -3px;
}

div.dayBarCls>div.seled {
  background: #3DA1FF;
}

div.jdtj3Cls {
  width: 2121px;
  height: 100%;
  margin-left: 50px;
  float: left;
}

div.jdtj3Cls>div.paihangbanCls {
  background-image: url("../../assets/images/dataScreen/bsyBg.png");
  background-repeat: no-repeat;
  background-size: 2100px 247px;
  background-position: center bottom;
  width: 2121px;
  height: 600px;
  display: flex;

}

div.jdtj3Cls>div.paihangbanCls>div.ban1Cls {
  background-image: url("../../assets/images/dataScreen/ban1.png");
  background-size: 419px 468px;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div.ban2Cls {
  background-image: url("../../assets/images/dataScreen/ban2.png");
  background-size: 419px 468px;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div.ban3Cls {
  background-image: url("../../assets/images/dataScreen/ban3.png");
  background-size: 419px 468px;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div.ban4Cls {
  background-image: url("../../assets/images/dataScreen/ban4.png");
  background-size: 419px 468px;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  flex: 1;
}

div.jdtj3Cls>div.paihangbanCls>div>div {
  font-size: 40px;
  text-align: center;
}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(1) {
  font-size: 80px;
  font-weight: bolder;
  margin-top: 110px;
}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(2) {}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(1).hsCls {
  color: #ffda31;
}

div.jdtj3Cls>div.paihangbanCls>div>div:nth-child(1).lanCls {
  color: #00eafe;
}

div.moxingCls {
  background-color: #091a34;
  width: 2121px;
  height: 1229px;
  margin-top: 40px;
}

.video {
  width: 2120px;
  height: 1229px;
}


div.jdtj3Cls>div.jdjhtjCls {
  width: 100%;
  float: left;
  height: 874px;
  margin-top: 40px;
  background: #091a34;
  border: 2px solid #1585D5;
  position: relative;
}

div.jdtj3Cls>div.jdjhtjCls>div.jiao1Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao1.png");
  width: 48px;
  height: 48px;
  position: absolute;
  top: -6px;
  left: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jiao2Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao2.png");
  width: 48px;
  height: 48px;
  position: absolute;
  top: -6px;
  right: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jiao3Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao3.png");
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: -6px;
  right: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jiao4Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao4.png");
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: -6px;
  left: -6px;
}

div.jdtj3Cls>div.jdjhtjCls>div.jdtjTitleBgCls {

  background-size: cover;
  background-position: center center;
  background-image: url("../../assets/images/dataScreen/tjTitleBg.png");
  width: 100%;
  height: 139px;
  text-align: center;
  font-size: 48px;
  line-height: 139px;
}

div.sikuangBoxCls {
  width: 100%;
  float: left;
  background: #091a34;
  border: 2px solid #1585D5;
  position: relative;
}

div.titleBgCls {

  background-size: cover;
  background-position: center center;
  background-image: url("../../assets/images/dataScreen/tjTitleBg.png");
  height: 139px;
  line-height: 139px;
  text-align: center;
  font-size: 48px;
}

div.sikuangBoxCls>div.jiao1Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao1.png");
  width: 48px;
  height: 48px;
  position: absolute;
  top: -6px;
  left: -6px;
}

div.sikuangBoxCls>div.jiao2Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao2.png");
  width: 48px;
  height: 48px;
  position: absolute;
  top: -6px;
  right: -6px;
}

div.sikuangBoxCls>div.jiao3Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao3.png");
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: -6px;
  right: -6px;
}

div.sikuangBoxCls>div.jiao4Cls {
  background-size: cover;
  background-image: url("../../assets/images/dataScreen/juxingjiao4.png");
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: -6px;
  left: -6px;
}

div.jdtj4Cls {
  width: 1500px;
  height: 100%;
  float: left;
  margin-left: 40px;
}

div.wenduBoxCls {

  background-size: cover;
  background-image: url("../../assets/images/dataScreen/wenduBg.png");
  width: 484px;
  height: 488px;
  margin-top: 40px;
  margin-left: 80px;
  text-align: center;
  padding-top: 140px;
  font-size: 130px;
  font-weight: bolder;
  float: left;
}

div.wulianwangValueBoxCls {
  float: left;
  font-size: 50px;

}

div.wulianwangValueBoxCls>div {
  height: 90px;
  line-height: 90px;
  width: 800px;
  text-indent: 70px;
  background-size: 50px 50px;
  background-repeat: no-repeat;
}

div.wulianwangValueBoxCls>div>span:nth-child(1) {
  display: block;
  float: left;
}

div.wulianwangValueBoxCls>div>span:nth-child(2) {
  display: block;
  float: right;
}

div.wulianwangValueBoxCls>div:nth-child(1) {
  background-position: 0px center;
  background-image: url("../../assets/images/dataScreen/wu1.png");
  padding-left: 0px;
}

div.wulianwangValueBoxCls>div:nth-child(2) {
  background-image: url("../../assets/images/dataScreen/wu2.png");
  background-position: 50px center;
  padding-left: 50px;
}

div.wulianwangValueBoxCls>div:nth-child(3) {
  background-image: url("../../assets/images/dataScreen/wu3.png");
  background-position: 100px center;
  padding-left: 100px;
}

div.wulianwangValueBoxCls>div:nth-child(4) {
  background-image: url("../../assets/images/dataScreen/wu4.png");
  background-position: 100px center;
  padding-left: 100px;
}

div.wulianwangValueBoxCls>div:nth-child(5) {
  background-image: url("../../assets/images/dataScreen/wu5.png");
  background-position: 50px center;
  padding-left: 50px;
}

div.wulianwangValueBoxCls>div:nth-child(6) {
  background-image: url("../../assets/images/dataScreen/wu6.png");
  background-position: 0px center;
  padding-left: 0px;
}

div.vedioRowCls {
  float: left;
}

div.vedioRowCls>div {
  width: 715px;
  height: 642px;
  float: left;
  margin-left: 20px;
  position: relative;
}

div.vedioColTitleCls {
  font-size: 45px;
  position: absolute;
  bottom: 0px;
  text-align: right;
  width: 100%;
  background-image: url("../../assets/images/dataScreen/vTitleBc.png");
  height: 102px;
  line-height: 102px;
  padding-right: 20px;
}

div.jdtj5Cls {
  background-color: #091a34;
  width: 1090px;
  height: 2780px;
  float: left;
  margin-left: 40px;
  position: relative;
}

div.biaotiCls {
  background-image: url("../../assets/images/dataScreen/kouhaoBg.png");
  background-repeat: no-repeat;
  background-size: 804px 58px;
  background-position: center center;
  height: 150px;
  width: 100%;

}

div.zhutiCls {
  font-size: 40px;
  color: #00B7EE;
}

div.zhutiCls>p {
  text-indent: 80px;
  padding-right: 40px;
  padding-left: 40px;
  text-align: justify;
  line-height: 70px;
  text-justify: distribute-all-lines;
}

div.welcomeCls {
  width: 100%;
  height: 852px;
  background-image: url("../../assets/images/dataScreen/huanyingguanli.png");
  background-size: 985px 752px;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

div.welcomeCls>div.tuCls {
  background-color: #00FB40;
  background-image: url("../../assets/images/dataScreen/ttt.png");
  width: 941px;
  height: 508px;
  border-radius: 30px;
  position: absolute;
  bottom: 70px;
  left: 76px;

}

div.erweimaCls {
  background-image: url("../../assets/images/dataScreen/erweima.png");
  width: 100%;
  height: 507px;
  background-size: 985px 507px;
  background-repeat: no-repeat;
  background-position: center center;

}

div.boxTitleCls {
  font-size: 18px;
  height: 33px;
  line-height: 33px;
  font-weight: bold;
  color: #CCCCCC;
}

div.jisuifBgCls {
  margin-top: 30px;
  background: #203F76;
  height: 37px;
  line-height: 37px;
}

div.jisuifBgCls>div:nth-child(1) {
  font-size: 16px;
  float: left;
  text-indent: 15px;
  color: #CCCCCC;
}

div.jisuifBgCls>div:nth-child(2) {
  font-size: 26px;
  float: right;
  padding-right: 25px;
  color: #23D1FF;
}

div.jisuifBgCls>div:nth-child(2)>span {
  font-size: 18px;
  margin-left: 10px;
  color: #fff;
}

div.jisuif2BgCls {
  margin-top: 30px;
  background: #203F76;
  height: 37px;
  line-height: 37px;
}

div.jisuif2BgCls>div:nth-child(1) {
  font-size: 16px;
  float: left;
  text-indent: 15px;
  width: 80px;
  color: #CCCCCC;
}

div.jisuif2BgCls>div:nth-child(2) {
  font-size: 16px;
  float: left;
  padding-right: 10px;
  color: #FED300;
  width: 100px;
}

div.jisuif2BgCls>div:nth-child(3) {
  font-size: 18px;
  float: left;
  padding-right: 3px;
  color: #Fff;
}

div.jisuif2BgCls>div:nth-child(4) {
  font-size: 16px;
  float: right;
  padding-right: 10px;
  color: #FED300;
}


div.boxTitleCls>img {
  vertical-align: middle;
  height: 15px;
  width: 62px;
  margin-left: 12px;
}

div.shujuTableCls {
  height: 85px;
  margin-top: 30px;
  position: relative;
}

div.shujuTableCls>div.splitCls {
  width: 2px;
  height: 40px;
  background: #23D1FF;
  opacity: 0.5;
  float: left;
  margin-top: 7px;
  margin-left: 25px;
  margin-right: 25px;
}

div.jdsjCls {
  width: 150px;
  height: 22px;
  font-size: 18px;
  float: left;
  color: #CCCCCC;
}

div.jdsjCls>div:nth-child(1) {
  text-align: center;
}

div.jdsjCls>div:nth-child(2) {
  text-align: center;
  color: #00B7EE;
  font-weight: bold;
  font-size: 30px;
  padding-top: 10px;
}

div.jdsjCls>div:nth-child(2)>span {
  color: #fff;
  font-weight: normal;
  font-size: 20px;
}

div.btfusCls {
  padding-top: 30px;
  height: 310px;
}

div.zzmjBoxCls {
  float: left;
  width: 49%;
  height: 210px;
}

div.yugunianzongliangBoxCls {
  float: left;
  width: 49%;
  height: 210px;
  margin-left: 2%;
}

div.vedioCls {
  width: 275px;
  height: 230px;
  background: #0E1639;
  border: 2px solid #123171;
  float: left;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 6px;
  position: relative;
}

div.vedioCls:nth-child(2) {
  margin-left: 10px;
  margin-right: 7px;
}

div.vedioCls>div.vedioTitleCls {
  background-image: url("../../assets/images/dataScreen/shipinjuxCsdiB.png");
  background-size: 145px 33px;
  background-repeat: no-repeat;
  background-position: center right;
  position: absolute;
  font-size: 20px;
  color: #fff;
  bottom: 25px;
  right: 0px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: right;
  padding-right: 15px;
}

.guwuzaliangzhongdituCls {
  width: 100%;
  height: 100%;
}

div.qixiangzhanCls {
  height: 212px;
  margin-top: 30px;
}

div.zbsjiyeuCls {
  display: flex;
  height: 126px;
  margin-top: 50px;
  color: #CCCCCC;
  width: 535px;
}


div.zbsjiyeuCls>div {
  float: left;
  font-size: 12px;
  flex: 1;
  background-repeat: no-repeat;
  height: 100px;
  text-align: center;
  padding-top: 83px;
  background-position: center top;

}

div.zbsjiyeuCls>div.qixiangzbsjCls1 {
  background-image: url("../../assets/images/dataScreen/qixiangzbsj1.png");
  background-size: 90px 69px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls2 {
  background-image: url("../../assets/images/dataScreen/qixiangzbsj2.png");
  background-size:  90px 69px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls3 {
  background-image: url("../../assets/images/dataScreen/qixiangzbsj3.png");
  background-size:  90px 69px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls4 {
  background-image: url("../../assets/images/dataScreen/qixiangzbsj4.png");
  background-size:  90px 69px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls5 {
  background-image: url("../../assets/images/dataScreen/qixiangzbsj5.png");
  background-size: 90px 69px;
}

div.zbsjiyeuCls>div.qixiangzbsjCls6 {
  background-image: url("../../assets/images/dataScreen/qixiangzbsj6.png");
  background-size:  90px 69px;
}

div.zbsjiyeuDataCls {
  width: 535px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: flex;
}

div.zbsjiyeuDataCls>div {
  float: left;
  flex: 1;
  text-align: center;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls1 {
  color: #A4DF11;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls2 {

  color: #F7E706;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls3 {

  color: #00FB40;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls4 {

  color: #EFBD01;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls5 {

  color: #02FFFF;
}

div.zbsjiyeuDataCls>div.zbsjiyeuDataValCls6 {

  color: #CD48FD;
}

div.wendubiaoCls {
  background-image: url("../../assets/images/dataScreen/wendubiao.png");
  width: 212px;
  height: 212px;
  font-size: 218px;
  font-weight: bold;
  text-align: center;
  line-height: 190px;
  background-size: cover;
  float: left;
  font-size: 72px;
}

div.wuliansbCls {
  width: calc(100% - 350px);
  height: 100%;
  float: left;
  padding-left: 22px;
}

div.wuliansbCls>div.wlsbRowCls {
  height: 38px;
}

div.wuliansbCls>div.wlsbRowCls>div {
  background-repeat: no-repeat;
  font-size: 14px;
  color: #00E620;
}

div.wlsb1IconCls {
  background-image: url("../../assets/images/dataScreen/wlsb1.png");
  background-size: 24px 22px;
  background-position: 0px 3px;
  float: left;
  text-indent: 25px;
}

div.wlsb2IconCls {
  background-image: url("../../assets/images/dataScreen/wlsb2.png");
  background-size: 19px 20px;
  background-position: 18px 0px;
  float: left;
  text-indent: 43px;
}

div.wlsb3IconCls {
  background-image: url("../../assets/images/dataScreen/wlsb3.png");
  background-size: 24px 22px;
  background-position: 40px 0px;
  float: left;
  text-indent: 75px;
}

div.wlsb4IconCls {
  background-image: url("../../assets/images/dataScreen/wlsb4.png");
  background-size: 24px 22px;
  background-position: 40px 0px;
  float: left;
  text-indent: 75px;
}

div.wlsb5IconCls {
  background-image: url("../../assets/images/dataScreen/wlsb5.png");
  background-size: 22px 20px;
  background-position: 18px 0px;
  float: left;
  text-indent: 43px;
}

div.wlsb6IconCls {
  background-image: url("../../assets/images/dataScreen/wlsb6.png");
  background-size:20px 17px;
  background-position: 0px 3px;
  float: left;
  text-indent: 25px;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2) {
  float: right;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span.status1Cls {
  background-color: #00E620;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span.status2Cls {
  background-color: #f00;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span:nth-child(1) {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 10px;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span:nth-child(2) {
  color: #fff;
  font-size: 16px;
  display: inline-block;
}

div.wuliansbCls>div.wlsbRowCls>div:nth-child(2)>span:nth-child(3) {
  color: #fff;
  display: inline-block;
  margin-left: 7px;
  font-size: 12px;
}

div.zuihouCls {
  width: 100%;
  height: 166px;
  margin-top: 30px;
}

div.zuihouCls>div.zuihLeftCls {
  float: left;
  width: 60%;
  height: 100%;
}

div.ysltjBoxCls {
  height: 66px;
  line-height: 66px;
  padding-left: 30px;
  background-image: url("../../assets/images/dataScreen/ysltj.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: left center;
  color: #CCCCCC;
}

div.ysltjBoxCls>div,
div.yfltjBoxCls>div {
  float: left;
  font-size: 15px;
}

div.ysltjBoxCls>div:nth-child(1) {
  width: 90px;
  color: #00E620;
}

div.ysltjBoxCls>div:nth-child(2),
div.yfltjBoxCls>div:nth-child(2) {
  width: 90px;
}

div.ysltjBoxCls>div:nth-child(3),
div.yfltjBoxCls>div:nth-child(3) {
  width: 13px;
}

div.ysltjBoxCls>div:nth-child(4),
div.yfltjBoxCls>div:nth-child(4) {
  width: 40px;
}

div.yfltjBoxCls {
  height: 66px;
  line-height: 66px;
  padding-left: 30px;
  background-image: url("../../assets/images/dataScreen/yfltj.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: left center;
  color: #CCCCCC;
}

div.yfltjBoxCls>div:nth-child(1) {
  width: 93px;
  color: #A4DF11;
}

div.zuihouCls>div.zuihRightCls {
  float: left;
  width: 40%;
  height: 100%;
}

div.sysdgBoxCls {
  font-size: 15px;
  height: 66px;
  line-height: 66px;
  padding-left: 30px;
  background-image: url("../../assets/images/dataScreen/baojin.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: left center;
}

div.sysdgBoxCls>div:nth-child(1),
div.sysdg2BoxCls>div:nth-child(1) {
  float: left;
}

div.sysdgBoxCls>div:nth-child(2),
div.sysdg2BoxCls>div:nth-child(2) {
  float: right;
}

div.sysdg2BoxCls {
  font-size: 46px;
  height: 130px;
  line-height: 130px;
  padding-left: 90px;
  background-image: url("../../assets/images/dataScreen/baojin.png");
  background-size: 60px 60px;
  background-repeat: no-repeat;
  background-position: left center;
}

div.rizhiCls {
  width: 100%;
  height: 2400px;
  margin-top: 80px;
}

div.rizhiCls>div:nth-child(1) {
  float: left;
  background-image: url("../../assets/images/dataScreen/gecuy.png");
  background-size: cover;
  width: 37px;
  height: 2375px;
  margin-top: 40px;

}

div.rizhiCls>div:nth-child(2) {
  float: left;
}

div.rizhiBoxCls {
  background-image: url("../../assets/images/dataScreen/duihuabox.png");
  background-size: cover;
  width: 1252px;
  height: 541px;
  padding: 40px 40px 40px 70px;
}

div.rizhizhutuCls {
  height: 100px;
  line-height: 70px;
  border-bottom: 1px solid #08578c;

}

div.rizhizhutuCls>div {
  float: left;
  font-size: 42px;
}

div.rizhizhutuCls>div:nth-child(1) {
  width: 400px;
}

div.rizhizhutuCls>div:nth-child(2) {
  width: 450px;
  background-image: url("../../assets/images/dataScreen/yuanjiaosplit.png");
  background-size: 12px 39px;
  background-repeat: no-repeat;
  background-position: left 18px;
  height: 100%;
  text-indent: 30px;
}

div.rizhizhutuCls>div:nth-child(3) {
  background-image: url("../../assets/images/dataScreen/yuanjiaosplit.png");
  background-size: 12px 39px;
  background-repeat: no-repeat;
  background-position: left 18px;
  height: 100%;
  text-indent: 30px;
}

div.rizhizhaopianCls {
  width: 100%;
  height: 320px;
  margin-top: 40px;
  display: flex;
}

div.rizhizhaopianCls>div:nth-child(1) {
  float: left;
  margin-right: 40px;
  flex: 1;
}

div.rizhizhaopianCls>div:nth-child(2) {
  float: left;
  flex: 1;

}

div.rizhizhaopianCls img {
  width: 100%;
  height: 100%;
}

div.yishengxiaomiCls {
  height: 80px;
  font-size: 50px;
  font-weight: 400;
  color: #00B7EE;
  line-height: 72px;
  margin-top: 100px;
  background-image: url("../../assets/images/dataScreen/sanniuao.png");
  background-repeat: no-repeat;
  background-size: 41px 43px;
  background-position: center left;
  text-indent: 60px;
}

div.yishengxiaomiJieshaoCls {
  text-indent: 80px;
  line-height: 80px;
  font-size: 40px;
  font-weight: 400;
  color: #00B7EE;
  text-align: justify;
  text-justify: distribute-all-lines;
}

div.orderTableCls {
  font-size: 40px;
  padding-top: 60px;
  padding-bottom: 60px;
}

div.orderTableCls>div.ysmiHeadCls {
  color: #00b7b6;
}

div.orderTableCls>div.ysmiHeadCls>div {
  float: left;
}

div.orderTableCls>div>div:nth-child(1) {
  width: 300px;
  text-indent: 50px;
}

div.orderTableCls>div>div:nth-child(2) {
  width: 400px;
}

div.orderTableCls>div>div:nth-child(3) {
  width: 400px;
}

div.orderTableCls>div>div:nth-child(4) {
  width: 300px;
}

div.orderTableCls>div {
  border-bottom: 1px solid #04496e;
  height: 131px;
  line-height: 131px;
}

div.orderTableCls>div>div {
  float: left;
}

div.orderTableCls>div:nth-child(2n) {
  color: #00a5d9;
}

div.orderTableCls>div:nth-child(2n+1) {
  color: #01d3cc;
}
div.zsboxCls{
  width: 100%;
  height: 559px;
  margin-top: 80px;
  display: flex;
}
div.zsboxCls>div:nth-child(1){
  flex:1;
  margin-right: 20px;
  background-image: url("../../assets/images/dataScreen/zsBox.png");
  background-size: 736px 559px;
}
div.zsboxCls>div:nth-child(2){
  flex:1;
  margin-left: 20px;
  background-image: url("../../assets/images/dataScreen/zsBox.png");
  background-size: 736px 559px;
}
div.zsTitleCls{
  font-size: 32px;
  margin-top: -20px;
  text-align: center;
}
div.zsFileCls{
  display: flex;
  height: 500px;
  margin: 40px;
}
div.zsFileCls>div>img{
  width: 100%;
  height: 100%;
}
div.zsFileCls>div:nth-child(1){
  flex: 1;
  background-color: #00E620;
  height: 460px;
  margin-right: 20px;
}
div.zsFileCls>div:nth-child(2){
  flex: 1;
  background-color: #00a5d9;
  height: 460px;
  margin-left: 20px;
}
div.gzbsCls{
  display: flex;
  margin-top: 40px;
  font-size: 28px;
}
div.gzbsCls>div{
  flex: 1;
  height: 200px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center top;
  line-height: 400px;
}
div.gzbsCls>div:nth-child(1){
  background-image: url("../../assets/images/dataScreen/zzz1.png");
  background-size: 110px 121px;
}
div.gzbsCls>div:nth-child(2){
  background-image: url("../../assets/images/dataScreen/zzz2.png");
  background-size: 110px 121px;
}
div.gzbsCls>div:nth-child(3){
  line-height: 65px;
  /*  background-image: url("../../assets/images/dataScreen/zzz3.png");
    background-size: 166px 166px;*/
}
div.gzbsCls>div:nth-child(4){
  background-image: url("../../assets/images/dataScreen/zzz4.png");
  background-size: 110px 121px;
}
div.gzbsCls>div:nth-child(5){
  background-image: url("../../assets/images/dataScreen/zzz5.png");
  background-size: 110px 121px;
}
.erCode {
  width: 166px;
  height: 166px;
  display: block;
  margin: 0 auto;
}
</style>